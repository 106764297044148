.footerOuterComponent{
    background-color: #07b6e8;
    padding: 0.5em 0 0.5em 0;
    margin: 1em 0 0 0;
}

.footerInnerComponent{
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
}

.idComponent{
    width: 33%;
}

.headerComponent {
    margin: auto;
    width: 70%;
    text-align: left;
}

.headerComponent h3 {
    color: rgb(66, 0, 128)
}

.detailComponent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
    margin: auto;
}

.detailComponent p {
    font-family: sans-serif;
    margin: 0.3em;
    /* font-weight: 600; */
}

.detailComponent img{
    width: 8em;
    height: 4em;
}

.logo-container{
    display: flex;
    justify-content: center;
    width: 6em;
    align-items: center;
}

.logo-container > img{
    border-radius: 8px;
    width: 100%;
    height: fit-content;
    display: block;
}

@media only screen and (max-width: 768px) {

    .footerInnerComponent{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .idComponent{
        width: 100%;
    }

    .headerComponent{
        text-align: center;
    }

    .detailComponent{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}