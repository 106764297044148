/* img {
    width: 10em;
    height: 10em;
} */

.contentMainOuterContainer{
    width: 100%;
}

.contentMainInnerContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 97%;
    margin: auto
}

.imageTextContainer{
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.5em;
    position: relative;
    background: white;
    border-radius: 0.5em;
    cursor: pointer;
    padding: 1rem;
    border-width: 1px;
    border-style: solid;
    border-color: #00A4BD #33475B #FF7A59;
    padding: 3px;
}

.imgContainer{
    width: 100%;
    height: 15em;
}

.imageTextContainer p{
    text-align: left;
    font-size: 0.9em;
    padding: 0.5em;
}

img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media only screen and (max-width: 768px) {
    .contentMainInnerContainer{
        display: grid;
        grid-template-columns: 1fr;
    }
}