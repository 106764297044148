.headerOuterComponent{
    background-color: #f0b338;
    margin: 0 0 1em 0;
}

.headerInnerComponent{
    width: 100%;
    display: flex;
    flex-direction: row;
}

.imageComponent{
    /* max-width: calc(1100px - (30px * 2)); */
    width: 60%;
}

.imageComponent img{
    width: 350px;
    /* height: 350px; */
}

.detailsComponent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
    margin: 2em 0;
}

.detailsComponent h3{
    font-family: sans-serif;
    margin: 0.2em 0;
    font-size: 3em;
}

.detailsComponent p{
    font-size: 1.2em;
    margin:0.2em;
}

@media only screen and (max-width: 768px) {

    .headerInnerComponent{
        display: flex;
        flex-direction: column;
    }

    .imageComponent{
        width: 100%;
    }

    .detailsComponent{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .detailsComponent h3{
        font-size: 2em;
    }
}