.relativeCard{
    width: 100%;
    height: 400px;
    position: relative;
    overflow: hidden;
}

.buttonOuterContainer{
    display: flex;
    justify-content: center;
}

.buttonContainer{

}

button{
    color: white;
    background-color: #1462ff;
    border: none;
    font-size: 1.2em;
    padding: 0.5em;
    cursor: pointer;
    border-radius: 0.5em;
    margin: 0 0 0 1em;
    width: 9em;
}

@media only screen and (max-width: 768px) {
    button{
        width: 4em;
    }
}